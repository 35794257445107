import React from "react";
import Layout from "../../components/Layout";
import Location from "../../components/Location/Location";

const ProjectsPage = ({ location }) => {
  const lang = "ja";

  // Page setting
  const pageTitle = "Location";
  const pageSlug = "location";
  const pageKeywords = [`アクセス`, `Location`];
  const pageDescription = "箕澤屋がある場所です";
  const pageImage = "";

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
      pageKeywords={pageKeywords}
      pageDescription={pageDescription}
      pageImage={pageImage}
    >
      <div className="page-title-wrapper">
        <h1 className="page-title container-lg">{pageTitle}</h1>
      </div>

      <div className="content-area">
        <Location />
      </div>
    </Layout>
  );
};

export default ProjectsPage;
