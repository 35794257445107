import React from "react";
import Loadable from "@loadable/component";
import Button from "../../components/Button";
import styles from "./Location.module.scss";
const LoadableMap = Loadable(() => import("../../components/Map"));

export default () => {
  return (
    <div className={styles.root}>
      <div className="container-lg">
        <section id="location" className={styles.location}>
          <div className={`${styles.location__content}`}>
            <div className={styles.location__map}>
              <LoadableMap />
            </div>
            <div className={styles.location__text}>
              <div>
                <div>
                  <h3>古民家箕澤屋（旧小原家）</h3>
                  <p>〒399-4601 長野県上伊那郡箕輪町中箕輪542-1</p>
                  <p>
                    JR飯田線沢駅から徒歩4分
                    <br />
                    中央自動車道伊北ICから車で約5分
                  </p>
                  <div className={styles.location__button}>
                    <a
                      className={`${styles.location__button__link} nohover`}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://goo.gl/maps/GZUs7oZErXpPLaFDA"
                    >
                      <Button text={"GoogleMaps"} icon={"external"} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
